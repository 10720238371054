package com.y9vad9.localization

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.staticCompositionLocalOf
import com.y9vad9.types.Article
import com.y9vad9.types.Project

interface Strings {
    val code: String

    companion object {
        val local: ProvidableCompositionLocal<Strings> = staticCompositionLocalOf {
            EnglishStrings
        }

        val current: Strings @Composable get() = local.current
    }
    val homeTitle: String
    val projectsTitle: String
    val librariesTitle: String
    val publicActivityTitle: String
    val blogTitle: String
    val latestPostsTitle: String
    val generalSummaryTitle: String

    val languagesISpeakTitle: String
    val languagesISpeakDescription: String

    val languageICodeWithTitle: String
    val languagesICodeWithDescription: String

    val developmentPlatformsTitle: String
    val developmentPlatformsDescription: String

    val myName: String
    val myDescription: String

    val learnMore: String

    val latestProjectsTitle: String

    val projects: List<Project>
    val libraries: List<Project>
    val articles: List<Article>

    val checkAll: String

    val exploreTitle: String
}
