package com.y9vad9.pages.ru.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/about-me/work-experience")
@Composable
fun Work_experiencePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/about-me/work_experience.md", mapOf("title" to listOf("Опыт работы")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Опыт работы")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("backend-на-php-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Backend на PHP (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("PHP был основой для ")
                com.varabyte.kobweb.silk.components.navigation.Link("/ru/archive/sketchcode") {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", моего первого опыта в бэкенд-разработке. Именно здесь я познакомился с оптимизацией сервера, управлением пользователями и сложностями масштабирования.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-на-java-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Android на Java (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java стала моим стартом в Android-разработке. К моменту создания ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" я прошёл путь от копирования кода до разработки продуманных архитектурных решений. Мои основные инструменты в то время: Retrofit, Room и RxJava2.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-на-kotlin-2020-настоящее-время") }) {
                org.jetbrains.compose.web.dom.Text("Android на Kotlin (2020–настоящее время)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Переход на Kotlin полностью изменил мой подход к написанию кода. Я освоил Jetpack Compose ещё в альфа-версии и с тех пор не изменяю ему. В моём стеке сейчас:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, RSocket, gRPC")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Kotlinx Coroutines & Serialization")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Decompose, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Gradle, Mockk, JUnit")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://developer.android.com/compose") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/airbnb/epoxy") {
                        org.jetbrains.compose.web.dom.Text("Epoxy")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("ajax-systems-декабрь-2022-апрель-2023") }) {
                org.jetbrains.compose.web.dom.Text("Ajax Systems (декабрь 2022 – апрель 2023)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("В Ajax Systems, ведущей украинской компании в сфере безопасности, я работал Android-инженером в команде Core. Мои достижения:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Уменьшил время загрузки приложения более чем на 50% благодаря оптимизации обработки клиент-серверных событий обновлений, что значительно повысило удовлетворённость основных установщиков и партнёров.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Возглавил разработку ключевых UI-компонентов, улучшив производительность команды на 20% за счёт перехода от реализации на основе Epoxy и View к Jetpack Compose.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Оптимизировал большую устаревшую кодовую базу на Java, перейдя на Kotlin, что сократило количество ошибок в продакшене на 30% и ускорило внедрение новых умных устройств.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Покинул компанию в связи с переездом в Германию.")
            }
        }
    }
}
