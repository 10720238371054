package com.y9vad9.pages.en.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/programming_languages")
@Composable
fun Programming_languagesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/about-me/programming_languages.md", mapOf("title" to listOf("Programming Languages that I use")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Programming Languages")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("kotlin-prod") }) {
                org.jetbrains.compose.web.dom.Text("Kotlin (Prod)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kotlin has been my go-to language since 2020. What began as an alternative to Java for Android development quickly became my default for everything from backend services to multiplatform projects. Even the website you’re browsing now was built with it. Every project I’ve worked on since 2021 has embraced Kotlin’s flexibility and conciseness.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("stack") }) {
                org.jetbrains.compose.web.dom.Text("Stack")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.coroutines (Flow, etc.), RxJava (first preferred).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.serialization, Gson (first preferred), Moshi.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, OkHttp, Retrofit, Fuel, Okio (first preferred)")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("RSocket")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("gRPC")
                    }
                    org.jetbrains.compose.web.dom.Text(", Rest")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android") }) {
                org.jetbrains.compose.web.dom.Text("Android")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("architectures") }) {
                org.jetbrains.compose.web.dom.Text("Architectures")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("MVP")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("MVI")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/contacts-app") {
                        org.jetbrains.compose.web.dom.Text("MVVM")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("design") }) {
                org.jetbrains.compose.web.dom.Text("Design")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("XML (with ViewBinding / DataBinding)")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(" (Material2 + Material3) (preferred)")
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Accompanist libraries")
                        }
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("libraries") }) {
                org.jetbrains.compose.web.dom.Text("Libraries")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Room, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Picasso, Glide, Coil")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Firebase")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Admob")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Android Lifecycle (ViewModel, etc)")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("You can check usage of some libraries ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/simple-vocabulary") {
                    org.jetbrains.compose.web.dom.Text("there")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("multiplatform") }) {
                org.jetbrains.compose.web.dom.Text("Multiplatform")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Compose Multiplatform")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("SQLDelight")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Decompose")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("java") }) {
                org.jetbrains.compose.web.dom.Text("Java")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java holds a place in my foundation, though I don’t use it as much now. My last major project was for a university – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/restaurant-coursework") {
                    org.jetbrains.compose.web.dom.Text("Telegram bot")
                }
                org.jetbrains.compose.web.dom.Text(". While Kotlin better suits my needs, I still keep up with Java’s updates — it’s like an old friend you don’t see often but never lose touch with.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The same one to Kotlin experience. I used it before.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("php") }) {
                org.jetbrains.compose.web.dom.Text("PHP")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My first programming love. PHP was how I discovered the joy of building things. Even though I’ve moved on, I respect how the language has evolved. It powered my first large-scale project, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", and gave me invaluable experience in optimization and scalability.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Stack:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Laravel")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Json, XML")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("etc")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Most often I used pure PHP writing not complex CRUDs.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("python") }) {
                org.jetbrains.compose.web.dom.Text("Python")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I’ve only scratched the surface of Python — writing scripts, building small pet projects, and helping a college friend learn the ropes. Fun fact: that friend now swears by Kotlin, thanks to me.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("typescript") }) {
                org.jetbrains.compose.web.dom.Text("TypeScript")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("A more recent addition to my toolkit, TypeScript came into play during ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/rrpc-ts") {
                    org.jetbrains.compose.web.dom.Text("rrpc")
                }
                org.jetbrains.compose.web.dom.Text(", a project for generating RPC services using RSocket. While I’m far from a TypeScript guru, I’m fascinated by its robust type system and features like union types.")
            }
        }
    }
}
