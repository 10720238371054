package com.y9vad9.pages.uk.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/summary")
@Composable
fun SummaryPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/about-me/summary.md", mapOf("title" to listOf("Загальний огляд")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("огляд") }) {
                org.jetbrains.compose.web.dom.Text("Огляд")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Народжений 26 квітня 2005 року в яскравому місті Київ, Україна, я — програміст, який перетворив цікавість на професію. Моя історія переплітається з любов'ю до коду, спільноти та постійного навчання. Але не будемо затягувати — ось все, що ви могли шукати:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("Мови програмування")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/about-me/project-details") {
                        org.jetbrains.compose.web.dom.Text("Проекти")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/about-me/work-experience") {
                        org.jetbrains.compose.web.dom.Text("Досвід роботи")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/about-me/interests") {
                        org.jetbrains.compose.web.dom.Text("Інтереси")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("інше") }) {
                org.jetbrains.compose.web.dom.Text("Інше")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Часовий пояс")
                    }
                    org.jetbrains.compose.web.dom.Text(": UTC+01:00")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Дата народження")
                    }
                    org.jetbrains.compose.web.dom.Text(": 26.04.2005")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Місто проживання")
                    }
                    org.jetbrains.compose.web.dom.Text(": Мюнхен")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Унікальне ім'я")
                    }
                    org.jetbrains.compose.web.dom.Text(": y9vad9 (ви можете знайти це ім'я на більшості соціальних мереж). ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/other/username-pronunciation") {
                        org.jetbrains.compose.web.dom.Text("Дивіться вимову")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Резюме")
                    }
                    org.jetbrains.compose.web.dom.Text(": ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/docs/Vadym_Yaroshchuk_CV.pdf") {
                        org.jetbrains.compose.web.dom.Text("Англійська версія")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Загальна кількість років у розробці")
                    }
                    org.jetbrains.compose.web.dom.Text(": 6 років (2 з Java та PHP, 4+ з Kotlin; ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/uk/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("див. інше або більше")
                    }
                    org.jetbrains.compose.web.dom.Text(")")
                }
            }
        }
    }
}
