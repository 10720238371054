package com.y9vad9.pages.en.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/about-me/project-details")
@Composable
fun ProjectsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/about-me/projects.md", mapOf("title" to listOf("My projects")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("my-projects") }) {
                org.jetbrains.compose.web.dom.Text("My Projects")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Here I want to give some details and history to my projects, not to replace existing page with my projects:")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("/en/archive/sketchcode") {
                    org.jetbrains.compose.web.dom.Text("Sketchcode: My First Public Project")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("In 2018, I created ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", a social network for developers using ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchware")
                }
                org.jetbrains.compose.web.dom.Text(" – a no-code Android app builder. It wasn’t just about sharing projects; it became a hub for tutorials, UI components, and collaboration. At its peak, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" supported 5,000 active users on a shoestring budget. Running it taught me more than just coding — it taught me resilience and resourcefulness.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-2") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://course.y9vad9.com/en") {
                    org.jetbrains.compose.web.dom.Text("Kotlin Course")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("When I started college, I noticed peers struggling to break into software engineering. So, I created a Kotlin course, first as a PDF for my group, then as a broader project. While currently on hold, I hope to revive it as a YouTube series someday. In addition, I had a Gradle course, but eventually replaced it with articles on dev.to:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf") {
                        org.jetbrains.compose.web.dom.Text("Gradle: From Newbie to Strong Fundamentals")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl") {
                        org.jetbrains.compose.web.dom.Text("Finding the Right Balance in Gradle Dependency Strategy")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-3") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates") {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
                org.jetbrains.compose.web.dom.Text(" takes the Pomodoro technique to the next level, allowing users to share timers with friends or teammates for collaborative focus. It’s one of those projects I love tinkering with but never quite finish—though I can’t let it go.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-4") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/brawlexorg") {
                    org.jetbrains.compose.web.dom.Text("Brawlex")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Currently my most ambitious project, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Starix")
                }
                org.jetbrains.compose.web.dom.Text(" helps players of ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Brawl Stars")
                }
                org.jetbrains.compose.web.dom.Text(" manage clubs, track performance, and get actionable insights. It started as a Telegram bot and now spans Android, iOS, and web platforms, powered by Kotlin Multiplatform.")
            }
        }
    }
}
