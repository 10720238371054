package com.y9vad9.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import com.y9vad9.HeadlineTextStyle
import com.y9vad9.SubheadlineTextStyle
import com.y9vad9.components.InfoCard
import com.y9vad9.components.PreviewLinkableInfoCard
import com.y9vad9.components.ReferencableHeader
import com.y9vad9.components.layouts.PageLayout
import com.y9vad9.components.widgets.IconButton
import com.y9vad9.localization.Strings
import com.y9vad9.toSitePalette
import com.y9vad9.types.Article
import com.y9vad9.types.Project
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.Div

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    //Breakpoint.MD { Modifier.margin { top(2.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle = CssStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle = CssStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}

@Page
@Composable
fun HomePage() {
    val ctx = rememberPageContext()

    PageLayout("Home") {
        Row(HeroContainerStyle.toModifier(), verticalAlignment = Alignment.CenterVertically) {
            Image(
                src = "landing-y9vad9-image.webp",
                modifier = Modifier.height(30.cssRem)
                    .displayIfAtLeast(Breakpoint.MD)
                    .borderRadius(2.cssRem)
                    .margin(right = 2.cssRem, bottom = 4.cssRem)
            )

            Box {
                ColorMode.current.toSitePalette()

                Column(Modifier.gap(1.cssRem)) {
                    Div(HeadlineTextStyle.toAttrs()) {
                        B {
                            SpanText(
                                Strings.current.myName, Modifier.color(
                                    when (ColorMode.current) {
                                        ColorMode.LIGHT -> Colors.Black
                                        ColorMode.DARK -> Colors.White
                                    }
                                )
                            )
                        }
                    }

                    Div(SubheadlineTextStyle.toAttrs()) {
                        SpanText(Strings.current.myDescription)
                    }

                    Row {
                        IconButton(
                            onClick = {
                                ctx.router.navigateTo(
                                    "https://t.me/vadymlog"
                                )
                            },
                        ) {
                            FaTelegram()
                        }

                        IconButton(
                            onClick = {
                                ctx.router.navigateTo(
                                    "https://github.com/y9vad9"
                                )
                            },
                        ) {
                            FaGithub()
                        }

                        IconButton(
                            onClick = {
                                ctx.router.navigateTo(
                                    "https://linkedin.com/in/y9vad9"
                                )
                            },
                        ) {
                            FaLinkedin()
                        }

                        IconButton(
                            onClick = {
                                ctx.router.navigateTo(
                                    "https://instagram.com/p/y9vad9"
                                )
                            },
                        ) {
                            FaInstagram()
                        }
                    }
                }
            }
        }

        Column(
            Modifier.fillMaxWidth().margin(topBottom = 4.cssRem),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            ReferencableHeader(
                text = Strings.current.generalSummaryTitle,
                link = "/${Strings.current.code}/summary",
            )

            SimpleGrid(
                numColumns(base = 1, md = 3),
                Modifier.alignContent(AlignContent.Center).gap(1.cssRem).fillMaxWidth()
            ) {
                InfoCard(
                    modifier = Modifier.fillMaxWidth(),
                    titleContent = {
                        FaLanguage(size = IconSize.SM)
                        B {
                            SpanText(
                                text = Strings.current.languagesISpeakTitle,
                            )
                        }
                    },
                    content = {
                        SpanText(
                            modifier = Modifier.fontSize(16.px).whiteSpace(WhiteSpace.PreLine),
                            text = Strings.current.languagesISpeakDescription
                        )
                    },
                )

                InfoCard(
                    modifier = Modifier.fillMaxWidth(),
                    titleContent = {
                        FaCode(size = IconSize.SM)
                        B {
                            SpanText(
                                modifier = Modifier.whiteSpace(WhiteSpace.PreLine),
                                text = Strings.current.languageICodeWithTitle,
                            )
                        }
                    },
                    content = {
                        SpanText(
                            modifier = Modifier.fontSize(16.px).whiteSpace(WhiteSpace.PreLine),
                            text = Strings.current.languagesICodeWithDescription
                        )
                    }
                )

                InfoCard(
                    modifier = Modifier.fillMaxWidth(),
                    titleContent = {
                        FaList(size = IconSize.SM)
                        B {
                            SpanText(
                                text = Strings.current.developmentPlatformsTitle,
                            )
                        }
                    },
                    content = {
                        SpanText(
                            modifier = Modifier.fontSize(16.px).whiteSpace(WhiteSpace.PreLine),
                            text = Strings.current.developmentPlatformsDescription,
                        )
                    }
                )
            }
        }

        Column(
            Modifier.fillMaxWidth().margin(topBottom = 4.cssRem),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            ReferencableHeader(
                text = Strings.current.latestProjectsTitle,
                link = "/projects",
            )

            SimpleGrid(
                numColumns(base = 1, md = 2),
                Modifier.gap(1.2.cssRem).fillMaxWidth().alignContent(AlignContent.Center)
            ) {
                Strings.current.projects.forEach { project ->
                    ProjectView(project)
                }
            }
        }

        Column(Modifier.fillMaxWidth()) {
            ReferencableHeader(
                text = Strings.current.latestPostsTitle,
                link = "/blog",
            )

            SimpleGrid(numColumns(base = 1, md = 2), Modifier.gap(1.2.cssRem)) {
                Strings.current.articles.take(2).forEach { article ->
                    ArticleView(article)
                }
            }
        }
        Column(Modifier.height(6.cssRem)) {}
    }
}

@Composable
private fun ArticleView(
    article: Article,
) {
    PreviewLinkableInfoCard(
        modifier = Modifier.fillMaxWidth(),
        previewSource = article.previewUrl,
        titleText = article.name,
        subText = article.description,
        referencesTo = article.url,
        buttonText = Strings.current.exploreTitle
    )
}

@Composable
private fun ProjectView(project: Project) {
    InfoCard(
        modifier = Modifier.fillMaxWidth(),
        titleContent = {
            project.imageUrl?.let {
                Image(
                    src = it,
                    modifier = Modifier
                        .size(24.px)
                        .margin(right = 8.px)
                        .then(if (project.roundImage) Modifier.borderRadius(6.px) else Modifier)
                )
            }

            SpanText(
                text = project.name,
                modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(FontSize.Large).fillMaxWidth(),
            )
        },
    ) {
        SpanText(
            project.description,
            modifier = Modifier
                .fontWeight(FontWeight.Lighter)
                .fontSize(FontSize.Medium)
                .height(Height.Inherit)
                .margin(top = 8.px)
        )
        Spacer()

        Box(
            modifier = Modifier.padding(top = 16.px).fillMaxWidth(),
            contentAlignment = Alignment.BottomEnd
        ) {
            val ctx = rememberPageContext()

            Button(
                onClick = { ctx.router.navigateTo(project.url) },
                modifier = Modifier.fillMaxWidth(),
                colorScheme = ColorSchemes.DeepPurple,
            ) {
                SpanText(
                    modifier = Modifier.fontFamily("JetBrains Mono"),
                    text = Strings.current.exploreTitle
                )
            }
        }
    }
}