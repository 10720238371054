package com.y9vad9.pages.uk.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/about-me/project-details")
@Composable
fun ProjectsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/about-me/projects.md", mapOf("title" to listOf("Мої проекти")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("мої-проекти") }) {
                org.jetbrains.compose.web.dom.Text("Мої проекти")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Тут я хочу розповісти деталі та історію моїх проектів, не замінюючи існуючу сторінку з моїми проектами:")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("/uk/archive/sketchcode") {
                    org.jetbrains.compose.web.dom.Text("Sketchcode: Мій перший публічний проект")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("У 2018 році я створив ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", соціальну мережу для розробників, які використовують ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchware")
                }
                org.jetbrains.compose.web.dom.Text(" — конструктор без коду для Android. Це було не просто для обміну проектами; проект став центром для туторіалів, UI-компонентів та співпраці. На піку ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" підтримував 5 000 активних користувачів на обмеженому бюджеті. Його запуск навчив мене не лише кодуванню — він навчив мене витривалості та винахідливості.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-2") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://course.y9vad9.com/en") {
                    org.jetbrains.compose.web.dom.Text("Курс з Kotlin")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Коли я почав навчання в університеті, я помітив, що мої одногрупники мають труднощі з тим, щоб потрапити у сферу програмування. Тому я створив курс з Kotlin, спершу як PDF для своєї групи, а потім як більший проект. Хоча наразі курс на паузі, я сподіваюся колись відродити його у вигляді серії на YouTube. Окрім цього, у мене був курс з Gradle, але я замінив його статтями на dev.to:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf") {
                        org.jetbrains.compose.web.dom.Text("Gradle: Від новачка до міцних основ")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl") {
                        org.jetbrains.compose.web.dom.Text("Знаходження правильного балансу у стратегії залежностей Gradle")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-3") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates") {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
                org.jetbrains.compose.web.dom.Text(" виводить техніку Помодоро на новий рівень, дозволяючи користувачам ділитися таймерами з друзями або командою для спільної концентрації. Це один з тих проектів, якими я люблю експериментувати, але так і не завершу — хоча не можу його залишити.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-4") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/brawlexorg") {
                    org.jetbrains.compose.web.dom.Text("Brawlex")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Зараз це мій найбільш амбітний проект. ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Brawlex")
                }
                org.jetbrains.compose.web.dom.Text(" допомагає гравцям ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Brawl Stars")
                }
                org.jetbrains.compose.web.dom.Text(" керувати клубами, відстежувати їх продуктивність та отримувати корисні аналітичні дані. Спочатку це був Telegram-бот, а зараз проект охоплює Android, iOS та веб-платформи, працюючи за допомогою Kotlin Multiplatform.")
            }
        }
    }
}
