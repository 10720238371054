package com.y9vad9.pages.en.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/about-me/work-experience")
@Composable
fun Work_experiencePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/about-me/work_experience.md", mapOf("title" to listOf("Work Experience")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Work Experience")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("backend-with-php-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Backend with PHP (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("PHP powered ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", my first experience with backend development. It introduced me to server optimization, user management, and the challenges of scaling.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-with-java-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Android with Java (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java was my starting point for Android. By the time I built ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", I had gone from copy-pasting snippets to developing smart architecture solutions. My go-to tools back then included Retrofit, Room, and RxJava2.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-with-kotlin-2020-present") }) {
                org.jetbrains.compose.web.dom.Text("Android with Kotlin (2020–Present)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Switching to Kotlin transformed how I wrote code. I embraced Jetpack Compose when it was still in alpha and haven’t looked back since. My stack now includes:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, RSocket, gRPC")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Kotlinx Coroutines & Serialization")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Decompose, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Gradle, Mockk, JUnit")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://developer.android.com/compose") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/airbnb/epoxy") {
                        org.jetbrains.compose.web.dom.Text("Epoxy")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("ajax-systems-december-2022-april-2023") }) {
                org.jetbrains.compose.web.dom.Text("Ajax Systems (December 2022 – April 2023)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("At Ajax Systems, Ukraine’s leading security company, I worked as an Android Engineer on the Core team. My highlights:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Improved application loading time by more than 50% through optimizing client-server update events parsing, leading to considerable satisfaction among major installers and partners.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Spearheaded the development of UI core components, achieving a 20% enhancement in team performance by refactoring code from Epoxy and View-based implementations to Jetpack Compose.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Optimized a large, outdated Java codebase by transitioning to Kotlin, resulting in a 30% reduction in production errors and accelerating the implementation of new lines of smart devices.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Left the company due to relocation to Germany.")
            }
        }
    }
}
