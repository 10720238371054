package com.y9vad9.pages.uk.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/programming_languages")
@Composable
fun Programming_languagesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/about-me/programming_languages.md", mapOf("title" to listOf("Мови програмування, які я використовую")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Мови програмування")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("kotlin-основна") }) {
                org.jetbrains.compose.web.dom.Text("Kotlin (Основна)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kotlin став моєю основною мовою з 2020 року. Що почалося як альтернатива Java для Android-розробки, швидко стало моїм вибором для всього: від серверних сервісів до мультиплатформених проектів. Навіть цей вебсайт, яким ви зараз користуєтесь, був побудований за допомогою Kotlin. Кожен проект, над яким я працював з 2021 року, використовує гнучкість і лаконічність Kotlin.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("стек") }) {
                org.jetbrains.compose.web.dom.Text("Стек")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.coroutines (Flow тощо), RxJava (перше вибране).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.serialization, Gson (перше вибране), Moshi.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, OkHttp, Retrofit, Fuel, Okio (перше вибране)")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("RSocket")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("gRPC")
                    }
                    org.jetbrains.compose.web.dom.Text(", Rest")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android") }) {
                org.jetbrains.compose.web.dom.Text("Android")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("архітектури") }) {
                org.jetbrains.compose.web.dom.Text("Архітектури")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("MVP")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("MVI")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/contacts-app") {
                        org.jetbrains.compose.web.dom.Text("MVVM")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("дизайн") }) {
                org.jetbrains.compose.web.dom.Text("Дизайн")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("XML (з ViewBinding / DataBinding)")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(" (Material2 + Material3) (переважно)")
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Бібліотеки Accompanist")
                        }
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("бібліотеки") }) {
                org.jetbrains.compose.web.dom.Text("Бібліотеки")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Room, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Picasso, Glide, Coil")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Firebase")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Admob")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Android Lifecycle (ViewModel тощо)")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ви можете перевірити використання деяких бібліотек ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/simple-vocabulary") {
                    org.jetbrains.compose.web.dom.Text("тут")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("мультиплатформені-проекти") }) {
                org.jetbrains.compose.web.dom.Text("Мультиплатформені проекти")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Compose Multiplatform")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("SQLDelight")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Decompose")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("java") }) {
                org.jetbrains.compose.web.dom.Text("Java")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java займає важливе місце в моїй основі, хоча я зараз використовую її не так часто. Мій останній великий проєкт був для університету — ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/restaurant-coursework") {
                    org.jetbrains.compose.web.dom.Text("Telegram-бот")
                }
                org.jetbrains.compose.web.dom.Text(". Хоча Kotlin краще підходить для моїх потреб, я все ще слідкую за оновленнями Java — це як старий друг, якого не часто бачиш, але з яким не втрачаєш зв’язку.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Той самий досвід, що і з Kotlin. Я використовував її раніше.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("php") }) {
                org.jetbrains.compose.web.dom.Text("PHP")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Моя перша програмна любов. PHP став для мене способом пізнати радість створення. Хоча я і перейшов до інших мов, я все одно поважаю, як розвивається ця мова. Вона була основою мого першого великомасштабного проекту, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", і дала мені безцінний досвід у оптимізації та масштабуванні.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Стек:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Laravel")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Json, XML")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("інше")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Найчастіше я використовував чистий PHP для написання нескладних CRUD-операцій.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("python") }) {
                org.jetbrains.compose.web.dom.Text("Python")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Я лише почав знайомитись з Python — писав скрипти, створював маленькі проекти та допомагав одному з друзів по університету вчитися основам. Цікавинка: той друг тепер використовує лише Kotlin, завдяки мені.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("typescript") }) {
                org.jetbrains.compose.web.dom.Text("TypeScript")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Дещо новіший інструмент у моєму арсеналі, TypeScript з'явився під час розробки ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/rrpc-ts") {
                    org.jetbrains.compose.web.dom.Text("rrpc")
                }
                org.jetbrains.compose.web.dom.Text(", проекту для генерації RPC-сервісів за допомогою RSocket. Хоча я ще не є гуру TypeScript, мене захоплює його потужна система типів та такі можливості, як юніони.")
            }
        }
    }
}
