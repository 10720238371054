package com.y9vad9.pages.ru.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/summary")
@Composable
fun SummaryPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/about-me/summary.md", mapOf("title" to listOf("Общая информация")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("резюме") }) {
                org.jetbrains.compose.web.dom.Text("Резюме")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Родился 26 апреля 2005 года в прекрасном городе Киев, Украина. Я — программист, который превратил любопытство в профессию. Моя история связана с любовью к коду, сообществу и непрерывному обучению. Но не будем затягивать — вот всё, что вам может быть интересно:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("Языки программирования")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/about-me/project-details") {
                        org.jetbrains.compose.web.dom.Text("Проекты")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/about-me/work-experience") {
                        org.jetbrains.compose.web.dom.Text("Опыт работы")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/about-me/interests") {
                        org.jetbrains.compose.web.dom.Text("Интересы")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("прочее") }) {
                org.jetbrains.compose.web.dom.Text("Прочее")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Часовой пояс")
                    }
                    org.jetbrains.compose.web.dom.Text(": UTC+01:00 (Берлинское время)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Дата рождения")
                    }
                    org.jetbrains.compose.web.dom.Text(": 26.04.2005")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Город проживания")
                    }
                    org.jetbrains.compose.web.dom.Text(": Мюнхен")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Уникальное имя")
                    }
                    org.jetbrains.compose.web.dom.Text(": y9vad9 (можете найти меня в большинстве соцсетей под этим ником). ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/other/username-pronunciation") {
                        org.jetbrains.compose.web.dom.Text("См. произношение")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Резюме")
                    }
                    org.jetbrains.compose.web.dom.Text(": ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/docs/Vadym_Yaroshchuk_CV.pdf") {
                        org.jetbrains.compose.web.dom.Text("Английский вариант")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Общий опыт в разработке")
                    }
                    org.jetbrains.compose.web.dom.Text(": 6 лет (2 года с Java и PHP, 4+ с Kotlin; ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/ru/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("подробнее")
                    }
                    org.jetbrains.compose.web.dom.Text(")")
                }
            }
        }
    }
}
