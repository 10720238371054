package com.y9vad9.pages.uk.other

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/other/username-pronunciation")
@Composable
fun Username_pronunciationPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/other/username_pronunciation.md", mapOf("title" to listOf("Вимова")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("вимова") }) {
                org.jetbrains.compose.web.dom.Text("Вимова")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Мій юзернейм, y9vad9, часто викликає цікавість, тож дозвольте пояснити. Його вимовляють як \"Я Вадя\" (Ya Vadya), що є жартівливим відсиланням до мого імені, Вадим, і має коріння в ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://uk.wikipedia.org/wiki/Leet") {
                    org.jetbrains.compose.web.dom.Text("LEET")
                }
                org.jetbrains.compose.web.dom.Text(" мові.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Натхнення прийшло від одного з моїх старих друзів – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://y9san9.me") {
                    org.jetbrains.compose.web.dom.Text("y9san9")
                }
                org.jetbrains.compose.web.dom.Text(", коли я хотів створити унікальний юзернейм, який не використовувався на всіх соціальних платформах.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Деталі:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("y та 9 поєднуються і звучать як \"Я\" (ya) в українській мові.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("vad походить безпосередньо від мого імені, Вадим.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("9 наприкінці — це стилізований спосіб завершити ім’я, аналогічно до \"9\" на початку для симетрії.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Отже, y9vad9 можна перекласти як \"Я Вадя\" (невимушена форма імені Вадим). Таким чином, вимова буде 'я-вад-я'.")
            }
        }
    }
}
