package com.y9vad9.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import com.y9vad9.localization.Strings
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun InfoCard(
    modifier: Modifier = Modifier,
    titleContent: (@Composable () -> Unit)? = null,
    content: @Composable () -> Unit,
) {
    Column(
        modifier = Modifier
            .borderRadius(16.px)
            .border(1.px, color = Color.darkgray, style = LineStyle.Solid)
            .padding(16.px)
            .then(modifier),
        verticalArrangement = Arrangement.spacedBy(0.3.cssRem)
    ) {
        titleContent?.let {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(1.2.cssRem)
            ) {
                it()
            }
        }

        content()
    }
}

@Composable
fun PreviewLinkableInfoCard(
    modifier: Modifier,
    previewSource: String,
    titleText: String,
    subText: String,
    referencesTo: String? = null,
    buttonText: String
) {
    InfoCard(modifier = modifier.padding(0.px)) {
        Image(
            src = previewSource,
            modifier = Modifier
                .fillMaxWidth()
                .borderRadius(topLeft = 16.px, topRight = 16.px)
        )

        Column(Modifier.fillMaxHeight().fillMaxWidth().padding(16.px), verticalArrangement = Arrangement.spacedBy(8.px)) {
            SpanText(
                text = titleText,
                modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(FontSize.Large),
            )
            SpanText(
                subText,
                modifier = Modifier
                    .fontWeight(FontWeight.Lighter)
                    .fontSize(FontSize.Medium)
                    .height(Height.MaxContent)
                    .margin(top = 8.px)
            )

            referencesTo?.let {
                val ctx = rememberPageContext()

                Box(
                    modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                    contentAlignment = Alignment.BottomCenter
                ) {
                    Button(
                        onClick = { ctx.router.navigateTo(referencesTo) },
                        modifier = Modifier.fillMaxWidth().align(Alignment.BottomCenter),
                        colorScheme = ColorSchemes.DeepPurple,
                    ) {
                        SpanText(
                            modifier = Modifier.fontFamily("JetBrains Mono"),
                            text = buttonText,
                        )
                    }
                }
            }
        }
    }
}