package com.y9vad9.pages.en.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/summary")
@Composable
fun SummaryPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/about-me/summary.md", mapOf("title" to listOf("General Summary")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("summary") }) {
                org.jetbrains.compose.web.dom.Text("Summary")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Born on April 26, 2005, in the vibrant city of Kyiv, Ukraine, I’m a software engineer who turned curiosity into a profession. My story is intertwined with a love for code, community, and continuous learning. But let’s not drag this out — here’s everything you might want to know:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("Programming Languages")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/about-me/project-details") {
                        org.jetbrains.compose.web.dom.Text("Projects")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/about-me/work-experience") {
                        org.jetbrains.compose.web.dom.Text("Work Experience")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/about-me/interests") {
                        org.jetbrains.compose.web.dom.Text("Interests")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("other") }) {
                org.jetbrains.compose.web.dom.Text("Other")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Timezone")
                    }
                    org.jetbrains.compose.web.dom.Text(": UTC+01:00")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Birthdate")
                    }
                    org.jetbrains.compose.web.dom.Text(": 26.04.2005")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Home city")
                    }
                    org.jetbrains.compose.web.dom.Text(": Munich")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Unique name")
                    }
                    org.jetbrains.compose.web.dom.Text(": y9vad9 (you may find on most socials by that name). ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/other/username-pronunciation") {
                        org.jetbrains.compose.web.dom.Text("See Pronunciation")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Resume")
                    }
                    org.jetbrains.compose.web.dom.Text(": ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/docs/Vadym_Yaroshchuk_CV.pdf") {
                        org.jetbrains.compose.web.dom.Text("English variant")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Total years in development")
                    }
                    org.jetbrains.compose.web.dom.Text(": 6 years (2 with Java and PHP, 4+ with Kotlin; ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/en/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("see other or more")
                    }
                    org.jetbrains.compose.web.dom.Text(")")
                }
            }
        }
    }
}
