package com.y9vad9

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.y9vad9.localization.EnglishStrings
import com.y9vad9.localization.RussianStrings
import com.y9vad9.localization.Strings
import com.y9vad9.localization.UkrainianStrings
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.vh

private const val COLOR_MODE_KEY = "y9vad9:colorMode"
val LOCALE_KEY = "y9vad9:locale"

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    ctx.config.initialColorMode = localStorage.getItem(COLOR_MODE_KEY)?.let { ColorMode.valueOf(it) } ?: ColorMode.DARK
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    SilkApp {
        val colorMode = ColorMode.current
        LaunchedEffect(colorMode) {
            localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
        }

        val strings = when (localStorage.getItem(LOCALE_KEY).takeUnless { it.isNullOrEmpty() } ?: window.navigator.language) {
            "uk" -> UkrainianStrings
            "ru" -> RussianStrings
            else -> EnglishStrings
        }

        CompositionLocalProvider(Strings.local provides strings) {
            Surface(
                SmoothColorStyle.toModifier()
                    .minHeight(100.vh)
                    .scrollBehavior(ScrollBehavior.Smooth)
            ) {
                content()
            }
        }
    }
}
