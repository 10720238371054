package com.y9vad9.pages.ru.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/about-me/interests")
@Composable
fun InterestsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/about-me/interests.md", mapOf("title" to listOf("Мои интересы")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("мои-интересы") }) {
                org.jetbrains.compose.web.dom.Text("Мои интересы")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Эта страница предназначена не для тех, кто хочет узнать меня как специалиста, а для тех, кто хочет узнать меня как личность. Здесь я хочу рассказать об интересных мне темах, кроме программирования, которое я люблю больше всего, и о которых мне нравится говорить или узнавать.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("история") }) {
                org.jetbrains.compose.web.dom.Text("История")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Я люблю исследовать прошлое. Хотя я не историк, меня привлекает понимание того, как люди жили, мыслили и формировали мир, который мы знаем.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("психология") }) {
                org.jetbrains.compose.web.dom.Text("Психология")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Люди меня завораживают — их мысли, эмоции и принятие решений. Мне интересно изучать такие концепции, как предвзятость подтверждения, которая значительно повлияла на то, как я думаю и взаимодействую с другими.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("игры") }) {
                org.jetbrains.compose.web.dom.Text("Игры")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Хотя сейчас я не активный игрок и играю только в некоторые мобильные игры в свободное время, я продолжаю следить за игровой индустрией. Однажды я хочу создать свою собственную 2D-игру — первые попытки уже были сделаны с помощью ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://godotengine.org/") {
                    org.jetbrains.compose.web.dom.Text("Godot")
                }
                org.jetbrains.compose.web.dom.Text(" и ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://korge.org") {
                    org.jetbrains.compose.web.dom.Text("Korge")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Некоторые мобильные игры, в которые я играю, включают Brawl Stars и иногда Genshin Impact. 😄")
            }
        }
    }
}
