import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.browser.api
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.EventSource
import org.w3c.dom.EventSourceInit
import org.w3c.dom.MessageEvent
import org.w3c.dom.`get`

private fun forceReloadNow() {
    window.stop()
    window.location.reload()
}

private fun handleServerStatusEvents() {
    val status = document.getElementById("status")!!
    var lastVersion: Int? = null
    var shouldReload = false

    val warningIcon = status.children[0]!!
    val spinnerIcon = status.children[1]!!
    val statusText = status.children[2]!!

    status.addEventListener("transitionend", {
        if (status.hasClass("fade-out")) {
            status.removeClass("fade-out")
            if (shouldReload) {
                forceReloadNow()
            }
        }
    })

    val eventSource = EventSource("/api/kobweb-status", EventSourceInit(true))
    eventSource.addEventListener("version", { evt ->
        val version = (evt as MessageEvent).data.toString().toInt()
        if (lastVersion == null) {
            lastVersion = version
        }
        if (lastVersion != version) {
            lastVersion = version
            if (document.asDynamic().hidden) {
                // Reload immediately when the page is not visible as the animation will not run
                forceReloadNow()
            } else if (status.className.isNotEmpty()) {
                shouldReload = true
            } else {
                // Not sure if we can get here but if we can't rely on the status transition
                // to reload we should do it ourselves.
                forceReloadNow()
            }
        }
    })

    eventSource.addEventListener("status", { evt ->
        val values: dynamic = JSON.parse<Any>((evt as MessageEvent).data.toString())
        val text = values.text as String
        val isError = (values.isError as String).toBoolean()
        if (text.isNotBlank()) {
            warningIcon.className = if (isError) "visible" else "hidden"
            spinnerIcon.className = if (isError) "hidden" else "visible"
            statusText.innerHTML = "<i>$text</i>"
            status.className = "fade-in"
        } else {
            if (status.className == "fade-in") {
                status.className = "fade-out"
            }
        }
    })

    eventSource.onerror = { eventSource.close() }
}

public fun main() {
    handleServerStatusEvents()

    window.api.logOnError = true

    AppGlobals.initialize(mapOf("title" to "Vadym Yaroshchuk"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.y9vad9.pages.HomePage() }
        ctx.router.register("/blog") { com.y9vad9.pages.BlogPage() }
        ctx.router.register("/projects") { com.y9vad9.pages.ArticlesPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", com.y9vad9.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", com.y9vad9.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown", com.y9vad9.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content", com.y9vad9.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", com.y9vad9.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header", com.y9vad9.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("hero-container", com.y9vad9.pages.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid", com.y9vad9.pages.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell", com.y9vad9.pages.HomeGridCellStyle)
        ctx.theme.registerVariant("-circle", com.y9vad9.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", com.y9vad9.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                com.y9vad9.components.sections.SideMenuSlideInAnim)
        com.y9vad9.initColorMode(ctx)
        com.y9vad9.initSiteStyles(ctx)
        com.y9vad9.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        com.y9vad9.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
