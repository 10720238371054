package com.y9vad9.pages.en.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/about-me/interests")
@Composable
fun InterestsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/about-me/interests.md", mapOf("title" to listOf("My Interests")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("my-interests") }) {
                org.jetbrains.compose.web.dom.Text("My Interests")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This page is not meant for those who want to know about me as a specialist, but as a person. I want to express here interesting topics for me, apart from programming that I love the most, that I like to talk or learn about.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("history") }) {
                org.jetbrains.compose.web.dom.Text("History")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I love exploring the past. While I’m no historian, I find myself drawn to understanding how people lived, thought, and shaped the world we know.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("psychology") }) {
                org.jetbrains.compose.web.dom.Text("Psychology")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("People fascinate me — their thoughts, emotions, and decision-making. I enjoy learning about concepts like confirmation bias, which has profoundly shaped how I think and interact with others.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("gaming") }) {
                org.jetbrains.compose.web.dom.Text("Gaming")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Even though I'm not an active player nowadays and play only some mobile games in my free time, I still keep an eye on the industry. Someday, I want to make my own 2D game – first attempts are already made with ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://godotengine.org/") {
                    org.jetbrains.compose.web.dom.Text("Godot")
                }
                org.jetbrains.compose.web.dom.Text(" and ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://korge.org") {
                    org.jetbrains.compose.web.dom.Text("Korge")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Some mobile games that I play include Brawl Stars and sometimes Genshin Impact. 😄")
            }
        }
    }
}
