package com.y9vad9.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.y9vad9.localization.Strings
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.H1

@Composable
fun ReferencableHeader(
    text: String,
    link: String,
) {
    val ctx = rememberPageContext()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(1.2.cssRem),
        modifier = Modifier.fillMaxWidth().onClick {
            ctx.router.navigateTo(link)
        }.cursor(Cursor.Pointer)
    ) {
        H1 {
            SpanText(
                modifier = Modifier.fontFamily("Jetbrains Mono").fillMaxWidth().onClick {
                    ctx.router.navigateTo(link)
                },
                text = text,
            )
        }
        FaArrowRight(size = IconSize.XL)
    }
}