package com.y9vad9.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import com.y9vad9.components.layouts.PageLayout
import com.y9vad9.localization.Strings
import com.y9vad9.types.Project
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2

@Page
@Composable
fun Articles() {
    val ctx = rememberPageContext()

    PageLayout(Strings.current.projectsTitle) {
        Column(
            modifier = Modifier.fillMaxSize().padding(1.cssRem).margin(bottom = 2.cssRem),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(1.cssRem),
        ) {
            H1 {
                SpanText(
                    modifier = Modifier.fontFamily("Jetbrains Mono"),
                    text = Strings.current.projectsTitle,
                )
            }

            Strings.current.projects.forEach { project ->
                ProjectView(ctx, project)
            }

            H2 {
                SpanText(
                    modifier = Modifier.fontFamily("Jetbrains Mono"),
                    text = Strings.current.librariesTitle,
                )
            }

            Strings.current.libraries.forEach { library ->
                ProjectView(ctx, library)
            }
        }
    }
}

@Composable
private fun ProjectView(ctx: PageContext, project: Project) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(16.px)
            .minWidth(450.px)
            .border(1.px, color = Color.darkgray, style = LineStyle.Solid)
            .padding(16.px)
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            project.imageUrl?.let {
                Image(
                    src = it,
                    modifier = Modifier
                        .size(24.px)
                        .margin(right = 8.px)
                        .then(if (project.roundImage) Modifier.borderRadius(6.px) else Modifier)
                )
            }


            SpanText(
                text = project.name,
                modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(FontSize.Large),
            )
        }
        SpanText(
            project.description,
            modifier = Modifier
                .fontWeight(FontWeight.Lighter)
                .fontSize(FontSize.Medium)
                .height(Height.Inherit)
                .margin(top = 8.px)
        )
        Spacer()

        Box(
            modifier = Modifier.padding(top = 16.px).fillMaxWidth(),
            contentAlignment = Alignment.BottomEnd
        ) {
            Button(
                onClick = { ctx.router.navigateTo(project.url) },
                modifier = Modifier.fillMaxWidth(),
                colorScheme = ColorSchemes.DeepPurple,
            ) {
                SpanText(
                    modifier = Modifier.fontFamily("JetBrains Mono"),
                    text = Strings.current.exploreTitle
                )
            }
        }
    }
}