package com.y9vad9.pages.en.archive.sketchcode

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/archive/sketchcode")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/archive/sketchcode/index.md", mapOf("title" to listOf("Sketchcode")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("sketchcode") }) {
                org.jetbrains.compose.web.dom.Text("Sketchcode")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("In 2018, I created Sketchcode, a social network for developers using Sketchware – a no-code Android app builder. It wasn’t just about sharing projects; it became a hub for tutorials, UI components, and collaboration. At its peak, Sketchcode supported 5,000 active users on a shoestring budget. Running it taught me more than just coding — it taught me resilience and resourcefulness.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This page is dedicated to telling the story of Sketchcode, my very first project, which began as an app for Sketchware, built using Sketchware itself. It was a labor of love that evolved far beyond the limits of no-code development, eventually moving into Android Studio and Java, marking the start of my programming journey.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("first-steps-global-feed-and-custom-blocks") }) {
                org.jetbrains.compose.web.dom.Text("First Steps: Global Feed and Custom Blocks")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The first release of Sketchcode introduced several core features designed for the Sketchware community:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A global feed where users could discover and share content.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Custom blocks that Sketchware users could easily integrate into their projects.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Code snippets, enabling more advanced users to include raw Java code via a dedicated building block.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("These features gave Sketchcode its foundation and made it an indispensable tool for Sketchware users looking to expand their creativity.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("second-major-version-community-and-tutorials") }) {
                org.jetbrains.compose.web.dom.Text("Second Major Version: Community and Tutorials")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The second major release took the app to the next level by making able the community to share their custom creations:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A custom block and snippet sharing feature, making it easier for users to collaborate.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A new tutorials section, featuring guides written by me to help users get started.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A UI builder for tutorials, so users could create and share their own instructional content.")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Note: Unfortunately, I don't have screenshots from that version, so I provide the only one I found out.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This version transformed Sketchcode into a collaborative space, fostering learning and creativity.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("third-major-version-projects-and-private-backups") }) {
                org.jetbrains.compose.web.dom.Text("Third Major Version: Projects and Private Backups")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The third major version introduced key new features to support and showcase user projects:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("New feature, allowing users to share their posts and Sketchware projects with the community.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A private projects feature for those who wanted a secure backup of their work.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This update cemented Sketchcode as a hub for Sketchware users to connect, collaborate, and store their work.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("fourth-major-version-big-ui-changes-and-engagement-features") }) {
                org.jetbrains.compose.web.dom.Text("Fourth Major Version: Big UI Changes and Engagement Features")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The fourth major release was all about improving user engagement and optimizing the experience:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A revamped UI, giving the app a modern look and better usability.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Enhancements to the Projects Market, including comments, likes, and other social features.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Supporting the projects feature was a significant challenge. With over 60GB of server storage and a non-monetized, free-for-all approach, I spent countless hours optimizing file structures and compressing shared projects to keep the service running smoothly.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("the-peak-4-5k-users") }) {
                org.jetbrains.compose.web.dom.Text("The Peak: 4-5K Users")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("At its peak, Sketchcode had 4,000–5,000 users, generating over 10 million requests per month. It was an exciting, exhausting, and deeply rewarding time. I often worked through the night, driven by the thrill of building and improving something people genuinely loved to use.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("final-major-feature-chat-system") }) {
                org.jetbrains.compose.web.dom.Text("Final major feature: Chat System")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The last major feature I introduced was simple chat functionality with support for images and stickers. This gave users a way to connect in real-time, further enhancing the sense of community within the app.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("reflections") }) {
                org.jetbrains.compose.web.dom.Text("Reflections")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Looking back, Sketchcode was more than just an app. It was my gateway into programming and a project that pushed me to grow in ways I never imagined. From humble beginnings in Sketchware to building a robust, feature-rich platform, it remains a defining moment in my journey as a developer.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Let me know if you'd like to adjust any sections or emphasize specific parts of the story!")
            }
        }
    }
}
