package com.y9vad9.pages.ru.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/about-me/project-details")
@Composable
fun ProjectsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/about-me/projects.md", mapOf("title" to listOf("Мои проекты")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("мои-проекты") }) {
                org.jetbrains.compose.web.dom.Text("Мои проекты")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Здесь я хочу рассказать подробнее о своих проектах и их истории, а не заменить существующую страницу с их списком:")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("/ru/archive/sketchcode") {
                    org.jetbrains.compose.web.dom.Text("Sketchcode: Мой первый публичный проект")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("В 2018 году я создал ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", социальную сеть для разработчиков, использующих ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchware")
                }
                org.jetbrains.compose.web.dom.Text(" — инструмент для создания Android-приложений без программирования. Это было не просто место для обмена проектами; ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" стал платформой для обучения, обмена UI-компонентами и сотрудничества. На пике своего развития ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" поддерживал 5,000 активных пользователей при минимальном бюджете. Управление этим проектом научило меня не только программированию, но и стойкости, а также находчивости.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-2") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://course.y9vad9.com/ru") {
                    org.jetbrains.compose.web.dom.Text("Курс по Kotlin")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Когда я начал учёбу в университете, я заметил, что многим однокурсникам трудно войти в сферу разработки программного обеспечения. Поэтому я создал курс по Kotlin — сначала в виде PDF-файла для своей группы, а затем расширил проект. Сейчас курс приостановлен, но в будущем я надеюсь возродить его в формате серии видео на YouTube. Кроме того, я разработал курс по Gradle, но со временем заменил его статьями на dev.to:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf") {
                        org.jetbrains.compose.web.dom.Text("Gradle: от новичка до сильных основ")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl") {
                        org.jetbrains.compose.web.dom.Text("Как найти баланс в стратегии зависимостей Gradle")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-3") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates") {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
                org.jetbrains.compose.web.dom.Text(" выводит технику Помодоро на новый уровень, позволяя пользователям делиться таймерами с друзьями или коллегами для совместной концентрации. Это один из тех проектов, которые я обожаю дорабатывать, но никак не могу закончить, хотя отпускать его тоже не хочу.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-4") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/brawlexorg") {
                    org.jetbrains.compose.web.dom.Text("Brawlex")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Мой самый амбициозный проект на данный момент, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Brawlex")
                }
                org.jetbrains.compose.web.dom.Text(", помогает игрокам ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Brawl Stars")
                }
                org.jetbrains.compose.web.dom.Text(" управлять клубами, отслеживать показатели и получать полезные инсайты. Он начался как Telegram-бот, а сейчас охватывает платформы Android, iOS и веб, используя Kotlin Multiplatform.")
            }
        }
    }
}
