package com.y9vad9.pages.en.other

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/other/username-pronunciation")
@Composable
fun Username_pronunciationPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/other/username_pronunciation.md", mapOf("title" to listOf("Pronunciation")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("pronunciation") }) {
                org.jetbrains.compose.web.dom.Text("Pronunciation")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My username, y9vad9, often sparks curiosity, so let me break it down for you. It's pronounced as \"Я Вадя\" (Ya Vadya), which is a playful nod to my name, Vadym, and has roots in ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://en.wikipedia.org/wiki/Leet") {
                    org.jetbrains.compose.web.dom.Text("LEET")
                }
                org.jetbrains.compose.web.dom.Text(" speak.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The inspiration came from one of my friends – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://y9san9.me") {
                    org.jetbrains.compose.web.dom.Text("y9san9")
                }
                org.jetbrains.compose.web.dom.Text(", when I wanted to create a unique username not used across all social platforms.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Details:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The y and 9 combine to sound like \"Я\" (ya) in Russian/Ukrainian, which means \"I\" or \"Me.\"")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The vad comes directly from my name, Vadym.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The 9 at the end is a stylized way to close it all off, mirroring the \"9\" in the beginning for symmetry.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So, y9vad9 translates to \"I am Vadya\" (a casual diminutive form of Vadym). The resulting way of pronouncing it is 'ya-vad-ya'.")
            }
        }
    }
}
