package com.y9vad9.pages.ru.other

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/other/username-pronunciation")
@Composable
fun Username_pronunciationPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/other/username_pronunciation.md", mapOf("title" to listOf("Произношение")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("произношение") }) {
                org.jetbrains.compose.web.dom.Text("Произношение")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Мой ник, y9vad9, часто вызывает любопытство, поэтому давайте разберем, как его произносить. Он читается как \"Я Вадя\", что является игривым отсылом к моему имени, Вадим, и имеет корни в ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://ru.wikipedia.org/wiki/Leet") {
                    org.jetbrains.compose.web.dom.Text("LEET")
                }
                org.jetbrains.compose.web.dom.Text(" стиле.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Идея пришла от одного из моих друзей — ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://y9san9.me") {
                    org.jetbrains.compose.web.dom.Text("y9san9")
                }
                org.jetbrains.compose.web.dom.Text(", когда я хотел создать уникальное имя, которое нигде больше не используется.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Детали:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("y и 9 вместе звучат как \"Я\" в русском/украинском языке.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("vad происходит от моего имени, Вадим.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("9 в конце — это стилизованный способ завершить имя, отражающий \"9\" в начале для симметрии.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Таким образом, y9vad9 переводится как \"Я Вадя\" и так же читается (неформальная уменьшительная форма от Вадим).")
            }
        }
    }
}
