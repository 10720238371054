package com.y9vad9.pages.ru.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/programming_languages")
@Composable
fun Programming_languagesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/about-me/programming_languages.md", mapOf("title" to listOf("Языки программирования, которые я использую")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Языки программирования")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("kotlin-продакшн") }) {
                org.jetbrains.compose.web.dom.Text("Kotlin (Продакшн)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kotlin стал моим основным языком программирования с 2020 года. То, что начиналось как альтернатива Java для разработки Android, быстро стало универсальным решением для всего: от бэкенд-сервисов до мультиплатформенных проектов. Даже этот сайт, который вы сейчас просматриваете, был создан с его помощью. Все проекты, над которыми я работал с 2021 года, используют гибкость и лаконичность Kotlin.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("стек") }) {
                org.jetbrains.compose.web.dom.Text("Стек")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.coroutines (Flow и т.д.), RxJava (предпочитаю первое).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.serialization, Gson, Moshi (предпочитаю первое).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, OkHttp, Retrofit, Fuel, Okio (предпочитаю первое).")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("RSocket")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("gRPC")
                    }
                    org.jetbrains.compose.web.dom.Text(", Http REST API.")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android") }) {
                org.jetbrains.compose.web.dom.Text("Android")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("архитектуры") }) {
                org.jetbrains.compose.web.dom.Text("Архитектуры")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("MVP")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("MVI")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/contacts-app") {
                        org.jetbrains.compose.web.dom.Text("MVVM")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("дизайн") }) {
                org.jetbrains.compose.web.dom.Text("Дизайн")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("XML (с ViewBinding / DataBinding).")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(" (Material2 + Material3) (предпочитаю).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Библиотеки Accompanist.")
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("библиотеки") }) {
                org.jetbrains.compose.web.dom.Text("Библиотеки")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Room, SQLDelight.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Picasso, Glide, Coil.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Firebase.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Admob.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Android Lifecycle (ViewModel и т.д.).")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Примеры использования некоторых библиотек вы можете найти ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/simple-vocabulary") {
                    org.jetbrains.compose.web.dom.Text("здесь")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("мультиплатформенные-проекты") }) {
                org.jetbrains.compose.web.dom.Text("Мультиплатформенные проекты")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Compose Multiplatform")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("SQLDelight")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Decompose")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("java") }) {
                org.jetbrains.compose.web.dom.Text("Java")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java занимает важное место в моих основах, хотя сейчас я использую её реже. Последний крупный проект был для университета – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/restaurant-coursework") {
                    org.jetbrains.compose.web.dom.Text("Telegram-бот")
                }
                org.jetbrains.compose.web.dom.Text(". Хотя Kotlin лучше подходит для моих нужд, я всё ещё слежу за обновлениями Java — как старый друг, с которым не видишься часто, но всегда остаёшься на связи.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Мой опыт работы с Java схож с опытом работы с Kotlin. Я использовал её раньше.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("php") }) {
                org.jetbrains.compose.web.dom.Text("PHP")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Моя первая любовь в программировании. PHP позволил мне открыть для себя радость создания чего-то нового. Несмотря на то, что я перешёл на другие языки, я уважаю то, как PHP эволюционировал. Он стал основой моего первого масштабного проекта — ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", дав мне бесценный опыт оптимизации и масштабируемости.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("стек-2") }) {
                org.jetbrains.compose.web.dom.Text("Стек")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Laravel.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Json, XML.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("и др.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Чаще всего я использовал чистый PHP, создавая несложные CRUD-приложения.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("python") }) {
                org.jetbrains.compose.web.dom.Text("Python")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Мой опыт с Python ограничивается написанием небольших скриптов, созданием маленьких пет-проектов и помощью другу из университета в изучении основ програмирования на Python. Забавный факт: этот друг теперь фанат Kotlin, благодаря мне.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("typescript") }) {
                org.jetbrains.compose.web.dom.Text("TypeScript")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("TypeScript стал более поздним дополнением к моему инструментарию, когда я начал работать над ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/rrpc-ts") {
                    org.jetbrains.compose.web.dom.Text("rrpc")
                }
                org.jetbrains.compose.web.dom.Text(", проектом для генерации RPC-сервисов с использованием RSocket. Хотя я далеко не гуру TypeScript, меня впечатляет его надёжная система типов и такие возможности, как объединённые типы.")
            }
        }
    }
}
