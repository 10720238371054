package com.y9vad9.pages.uk.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/about-me/interests")
@Composable
fun InterestsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/about-me/interests.md", mapOf("title" to listOf("Мої інтереси")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("мої-інтереси") }) {
                org.jetbrains.compose.web.dom.Text("Мої інтереси")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ця сторінка не для тих, хто хоче дізнатися про мене як спеціаліста, а як людину. Тут я хочу поділитися цікавими темами для мене, окрім програмування, яке я люблю найбільше, і які мені подобається обговорювати чи вивчати.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("історія") }) {
                org.jetbrains.compose.web.dom.Text("Історія")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Я люблю досліджувати минуле. Хоч я й не історик, мене приваблює розуміння того, як люди жили, думали та формували світ, який ми знаємо сьогодні.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("психологія") }) {
                org.jetbrains.compose.web.dom.Text("Психологія")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Люди мене захоплюють — їхні думки, емоції та процеси прийняття рішень. Мені цікаво вивчати такі концепції, як підтвердження власної думки, що суттєво змінило моє мислення та взаємодію з іншими.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("ігри") }) {
                org.jetbrains.compose.web.dom.Text("Ігри")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Хоч я зараз і не активний гравець і граю лише деякі мобільні ігри у вільний час, я все одно слідкую за індустрією. Коли-небудь я хочу створити свою 2D гру – перші спроби вже зроблені за допомогою ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://godotengine.org/") {
                    org.jetbrains.compose.web.dom.Text("Godot")
                }
                org.jetbrains.compose.web.dom.Text(" та ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://korge.org") {
                    org.jetbrains.compose.web.dom.Text("Korge")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Деякі мобільні ігри, у які я граю: Brawl Stars та іноді Genshin Impact. 😄")
            }
        }
    }
}
